body {
  background-color: #ececec;
  min-height: 100vh;
  font-size: 14px;
}

a {
  color: #EC9A29;
  text-decoration: none;
}

a:hover {
  color: #80500d;
}

header {
  text-align: center;
}

.title {
  color: #143642;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 1rem;
  margin: 30px;
}

.card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
}

.card__title {
  font-weight: 700;
}

.card__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.card__image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.card__store__in-stock {
  font-size: 10px;
  float: right;
}

.options {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 0 30px;
  grid-gap: 1rem;
}

@media only screen and (max-width: 489px) {
  .card {
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .options {
    grid-template-columns: 1fr;
  }
}
