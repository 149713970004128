.search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input {
  max-width: 1440px;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 1rem;
  border: 1px solid #D0CFCE;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.search__input:focus{
  border: 1px solid #008ABF;
  transition: 0.35s ease;
  color: #008ABF;
}
